import { ReactNode, Ref } from 'react';
import BaseFirebaseItemService from '../common/item_service/base_firebase_item_service';

import { BeadPattern as BeadPatternModel } from '../model/bead_pattern';

import * as fs from 'firebase/firestore';
import { Button } from 'grommet';
import i18next from 'i18next';
import { RiFireLine } from 'react-icons/ri';
import BeadPattern from '../bead_pattern/bead_pattern';
import Application from '../common/application/application';
import { ItemHandle } from '../common/item/item_handle';
import {
  ListConstraint,
  StaticOptionsListConstraintMeta,
  ValueListConstraint,
} from '../common/list/filter';
import { FirebaseTagService } from '../common/tag/tag_service';
import { ViewDetail } from '../common/view/view';
import { EditBeadPattern, NewBeadPattern } from '../edit/edit';
import { getBeadById } from '../model/bead';
import { getPegboardById, pegboards } from '../model/pegboard';
import PegboardSelectItem from '../pegboard/pegboard_select_item';
import { fusePathForItem } from '../routes/routes';

export default class BeadPatternItemService extends BaseFirebaseItemService<BeadPatternModel> {
  constructor(application: Application) {
    super('bead_pattern', 'bead_pattern_likes', 'item_id', application);
  }

  override async deserializeItem(
    id: string,
    data: { [fieldPath: string]: any }
  ): Promise<BeadPatternModel> {
    return await deserializeBeadPattern(
      id,
      data,
      this.application.tagService as FirebaseTagService
    );
  }

  override serializeItem(
    item: BeadPatternModel,
    uid: string
  ): { [fieldPath: string]: any } {
    return serializeBeadPattern(item, uid);
  }

  override getItemViewOverview(item: BeadPatternModel): ReactNode {
    return <BeadPattern beadPattern={item} />;
  }

  override getDefaultListConstraints(
    languages: string[],
    uid?: string | undefined
  ): ListConstraint[] {
    return [
      ...super.getDefaultListConstraints(languages, uid),
      new ValueListConstraint(
        undefined,
        new StaticOptionsListConstraintMeta(
          (pegboard) =>
            pegboard ? [fs.where('pegboard', '==', pegboard.id)] : [],
          (_) => [],
          pegboards,
          (pegboard) =>
            pegboard ? i18next.t(`pegboard.model.${pegboard.id}.name`) : '',
          (pegboard) => <PegboardSelectItem pegboard={pegboard} />,
          i18next.t('filter.pegboard.placeholder') ?? undefined
        )
      ),
    ];
  }

  override getItemViewDetail(
    item: BeadPatternModel,
    className: string,
    itemHandle: Ref<ItemHandle>
  ): ViewDetail {
    return {
      detailView: (
        <div className={`${className} beadPatternPrintStyle`}>
          <style>{`
          @media print {
            @page {
              size: auto;
              margin: 0mm;
            }

            body {
              position: relative;
            }
            
            body * {
              visibility: hidden;
            }

            body .no-print {
              display:none;
            }

            .beadPatternPrintStyle * {
              visibility: visible;
            }
            
            .beadPatternPrintStyle {
              visibility: visible;
              position: absolute;
              top: 0;
              left: 0;
              margin: 1cm;
              width: ${item.widthMM}mm !important;
            }
          }
        `}</style>
          <BeadPattern beadPattern={item} ref={itemHandle} />
        </div>
      ),
      additionalFunctions: (logEvent, navigate) => [
        <Button
          key="fuse"
          icon={<RiFireLine size={24} color={'orange'} />}
          onClick={() => {
            logEvent('fuse_bead_pattern');
            navigate(fusePathForItem(item.id!));
          }}
        />,
      ],
    };
  }

  override getItemViewEdit(): ReactNode {
    return <EditBeadPattern />;
  }

  override getItemViewNew(): ReactNode {
    return <NewBeadPattern />;
  }
}

export function serializeBeadPattern(
  beadPattern: BeadPatternModel,
  uid: string
): { [fieldPath: string]: any } {
  const beads = beadPattern.beads
    .map((bead) => (bead ? bead.id : ''))
    .join(',');
  return {
    beads: beads,
    pegboard: beadPattern.pegboard.id,
    version: 1,
    uid: uid,
    public: beadPattern.public,
    tags: FirebaseTagService.toTagDocumentReferences(beadPattern.tags),
    approved: beadPattern.approved,
    rotation: beadPattern.pegboard.rotation,
  };
}

export async function deserializeBeadPattern(
  id: string,
  data: { [fieldPath: string]: any },
  tagService: FirebaseTagService
): Promise<BeadPatternModel> {
  const beads = (data.beads as string).split(',').map((beadId) => {
    const beadIdTrimmed = beadId.trim();
    return beadIdTrimmed.length === 0 ? undefined : getBeadById(beadIdTrimmed);
  });
  let pegboard = getPegboardById(data.pegboard);
  const rotation = (data.rotation as number) ?? 0;
  if (rotation !== 0) {
    pegboard = pegboard.rotate(rotation);
  }
  return new BeadPatternModel(
    id,
    data.uid as string,
    data.public as boolean,
    data.approved as boolean,
    new Set(data.likers),
    await tagService.fromTagDocumentReferences(data.tags),
    pegboard,
    beads
  );
}
