import { logEvent } from 'firebase/analytics';
import { Box, Button, Layer, Menu, Text } from 'grommet';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  RiArrowGoBackLine,
  RiDeleteBin5Line,
  RiDownload2Line,
  RiFileCopyLine,
  RiPencilLine,
  RiPrinterLine,
} from 'react-icons/ri';
import { BsFiletypePdf } from 'react-icons/bs';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useAnalytics, useUser } from 'reactfire';
import ApplicationContext from '../application/context';
import { ItemHandle } from '../item/item_handle';
import ItemMeta from '../item_meta/item_meta';
import { Item } from '../model/item';
import {
  copyPathForItem,
  editPathForItem,
  overviewPath,
  useViewPathParams,
  viewPathForItem,
} from '../routes/routes';
import Share from '../share/share';
import styles from './styles.module.scss';

export type LogViewEventFunction = (name: string) => void;

export interface ViewDetail {
  detailView: ReactNode;
  additionalFunctions(
    logEvent: LogViewEventFunction,
    navigate: NavigateFunction
  ): Array<ReactNode>;
}

function View() {
  const pathParams = useViewPathParams();
  const [deleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);
  const [item, setItem] = useState<Item<any>>();
  const [viewDetail, setViewDetail] = useState<ViewDetail | undefined>();

  const navigate = useNavigate();
  const { data: user } = useUser();
  const analytics = useAnalytics();
  const { t } = useTranslation();
  const itemService = useContext(ApplicationContext).itemService;

  const eventData = {
    item_id: item?.id,
  };

  const itemRef = useRef<ItemHandle>(null);

  useEffect(() => {
    itemService.getItem(pathParams.id!).then((item) => {
      setItem(item);
      if (item) {
        setViewDetail(
          itemService.getItemViewDetail(item, styles.item, itemRef)
        );
      }
    });
  }, [pathParams.id, itemService, itemRef]);

  async function onDeleteItem() {
    navigate(overviewPath);
    await itemService.deleteItem(item!);
  }

  function onDownloadItemAsPng() {
    itemRef.current!.downloadPng();
  }

  function onDownloadItemAsPdf() {
    itemRef.current!.downloadPdf();
  }

  const editable = user && item && user.uid === item.uid;
  return (
    <>
      <Helmet>
        <title>{`${t('app.title')} - ${t('view.pageTitlePrefix')} ${
          item ? item.id : ''
        }`}</title>
        <link
          rel="canonical"
          href={`${window.location.protocol}//${
            window.location.host
          }${viewPathForItem(pathParams.id!)}`}
        />
      </Helmet>
      <Box direction={'column'} data-testid={'c-view'}>
        <Box direction={'row'} style={{ flexShrink: 0 }} className={'no-print'}>
          <Button
            icon={<RiArrowGoBackLine size={24} />}
            onClick={() => {
              logEvent(analytics, 'back_button_click', eventData);
              navigate(overviewPath);
            }}
          />
          {editable && (
            <Button
              data-testid={'edit-button'}
              icon={<RiPencilLine size={24} />}
              onClick={() => {
                logEvent(analytics, 'edit_item_click', eventData);
                navigate(editPathForItem(pathParams.id!));
              }}
            />
          )}
          {editable && (
            <Button
              data-testid={'delete-button'}
              icon={<RiDeleteBin5Line size={24} />}
              onClick={() => {
                setDeleteConfirmationPopupOpen(true);
              }}
            />
          )}
          {user && (
            <Button
              data-testid={'copy-button'}
              icon={<RiFileCopyLine size={24} />}
              onClick={() => {
                logEvent(analytics, 'copy_item_click', eventData);
                navigate(copyPathForItem(pathParams.id!));
              }}
            />
          )}
          {user && deleteConfirmationPopupOpen && (
            <Layer
              data-testid={'c-delete-confirmation'}
              onClickOutside={(_) => setDeleteConfirmationPopupOpen(false)}
              onEsc={(_) => setDeleteConfirmationPopupOpen(false)}
              responsive={false}>
              <Box direction={'column'} pad={'medium'}>
                <Text>{t('view.deleteConfirmation.text')}</Text>
                <Box
                  direction={'row'}
                  margin={'medium'}
                  gap={'small'}
                  alignSelf={'center'}>
                  <Button
                    data-testid={'confirmation-button'}
                    primary={true}
                    label={t('view.deleteConfirmation.confirm')}
                    onClick={() => {
                      logEvent(analytics, 'delete_item_click', eventData);
                      setDeleteConfirmationPopupOpen(false);
                      return onDeleteItem();
                    }}
                  />
                  <Button
                    label={t('view.deleteConfirmation.cancel')}
                    onClick={() => {
                      return setDeleteConfirmationPopupOpen(false);
                    }}
                  />
                </Box>
              </Box>
            </Layer>
          )}

          <Menu
            icon={<RiPrinterLine size={24} />}
            items={[
              {
                label: t('view.pdf'),
                icon: <BsFiletypePdf />,
                gap: 'xsmall',
                onClick: () => {
                  logEvent(analytics, 'pdf_item_click', eventData);
                  onDownloadItemAsPdf();
                },
              },
              {
                label: t('view.print'),
                icon: <RiPrinterLine />,
                gap: 'xsmall',
                onClick: () => {
                  logEvent(analytics, 'print_item_click', eventData);
                  window.print();
                },
              },
            ]}
          />
          <Button
            icon={<RiDownload2Line size={24} />}
            onClick={() => {
              logEvent(analytics, 'download_item_click', eventData);
              onDownloadItemAsPng();
            }}
          />
          <Share
            iconSize={24}
            disabled={!(item && item!.public && item.approved)}
            eventData={eventData}
          />
          {viewDetail?.additionalFunctions(
            (eventName) => logEvent(analytics, eventName, eventData),
            navigate
          ) ?? []}
        </Box>
        {viewDetail && (
          <Box className={styles.item}>
            {viewDetail.detailView}
            <ItemMeta item={item!} />
          </Box>
        )}
      </Box>
    </>
  );
}

export default View;
