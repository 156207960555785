import React, {
  ReactElement,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Item } from '../model/item';
import { ItemHandle } from '../item/item_handle';
import SvgAsPngRenderer from './renderer/svg_as_png_renderer';
import Renderer from './renderer/renderer';

export enum RenderType {
  imaged,
  hybrid,
  svg,
}

interface SvgImgRendererProps extends React.PropsWithChildren<{}> {
  render: RenderType;
  item: Item<any>;
  fileNamePrefix: string;
  renderer?: Renderer;
  viewBox: string;
  width: number;
  height: number;
}

export const defaultRenderer = new SvgAsPngRenderer();

function _SvgImgRenderer(
  {
    children,
    render,
    item,
    fileNamePrefix,
    viewBox,
    width,
    height,
    renderer = defaultRenderer,
  }: SvgImgRendererProps,
  ref: Ref<ItemHandle>
) {
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [imageDataUri, setImageDataUri] = useState<string>();

  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    downloadPng: () => {
      const scale = width / svgRef!.viewBox.baseVal.width;
      renderer.saveAsPng(
        item,
        svgRef!,
        `${fileNamePrefix}_${item.id}.png`,
        scale
      );
    },
    downloadPdf: () => {
      renderer.saveAsPdf(item, svgRef!, `${fileNamePrefix}_${item.id}.pdf`);
    },
    getSvgData: () => {
      const scale = width / svgRef!.viewBox.baseVal.width;
      return renderer.toSvg(svgRef!, scale);
    },
    isReady: () => {
      return svgRef !== null;
    },
  }));

  const onSvgRefChange = useCallback(
    (svgElement: SVGSVGElement) => {
      setSvgRef(svgElement);
      if (svgElement === null) {
        return;
      }
      if (render === RenderType.hybrid || render === RenderType.imaged) {
        const scale = width / svgElement.viewBox.baseVal.width;
        renderer.toPngUri(item, svgElement, scale).then((url: string) => {
          setImageDataUri(url);
        });
      }
    },
    [item, renderer, width, render]
  );

  let image: ReactElement<any> | null = null;
  if (render === RenderType.imaged || render === RenderType.hybrid) {
    if (imageDataUri) {
      image = (
        <img
          src={imageDataUri}
          className={styles.image}
          alt={`${t('item.imgAltTextPrefix')} ${item.id ?? ''}`}
        />
      );
    } else if (render === RenderType.imaged) {
      image = <div className={styles.placeholder} />;
    }
  }
  const showSvg =
    render === RenderType.svg ||
    (render === RenderType.hybrid && !imageDataUri);

  return (
    <div data-testid={'c-rendered-item'} className={styles.container}>
      {image !== null && image}
      <svg
        ref={onSvgRefChange}
        viewBox={viewBox}
        width={showSvg ? width : 0}
        height={showSvg ? height : 0}
        className={`${styles.svg} ${showSvg ? '' : styles.hidden}`}>
        {children}
      </svg>
    </div>
  );
}

export const SvgImgRenderer = forwardRef(_SvgImgRenderer);
export default SvgImgRenderer;
